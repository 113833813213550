/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { countries } from "countries-list";
import { useFeatureFlagEnabled } from 'posthog-js/react'
import styles from "./App.module.css";
import Form from "./components/Form";
import { BACKEND_URL, PROD_RATE_BASE_URL, DASHBOARD_ACCESS_TOKEN } from "./utils/env";

import PaymentSuccessful from "./components/PaymentSuccessFul";
import InvalidPaymentToken from "./components/InvalidToken";
import useKlashaPayment from "./libs/use-klasha";
import { ReactComponent as LogoPink } from "./assets/icons/loadericon.svg";
import { ReactComponent as LogoIcon } from "./assets/icons/logo.svg";

const whitelistedOrigins = [
  "https://stitch-redirect.herokuapp.com",
  "https://stitch-redirect-o542m.ondigitalocean.app",
  "https://secure.stitch.money",
  "https://plugins-redirect.klasha.com",
];

function App() {
  const [currencyPreconfigured, setCurrencyPreconfigured] = useState(false);
  const [amountPreconfigured, setAmountPreconfigured] = useState(false);
  const flagEnabled = useFeatureFlagEnabled('enable_inspect')    
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [merchantKey, setMerchantKey] = useState("");
  const [businessObj, setBusinessObj] = useState("");
  const [taxRefId, setTaxRefId] = useState("");

  const [currency, setCurrency] = useState("");
  const [payCurrency, setPayCurrency] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [amount, setAmount] = useState(1);
  const [payLinkType, setPayLinkType] = useState("");
  const [interval, setInterval] = useState(null);
  const [limit, setLimit] = useState(30);
  const [paylinkId, setPaylinkId] = useState(null);
  const [referred, setReferred] = useState(false)
  const [extraPayload, setExtraPayload] = useState({});
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [thePayingCurrencies, setThePayingCurrencies] = useState([]);
  const [payCurrencyWithoutIndex, setPayCurrencyWithoutIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [isBaePayLink, setIsBaePayLink] = useState(false);
  const [baeEmail, setBaeEmail] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("0.00");
  const [emailError, setEmailError] = useState("");
  const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  const origin = window.location.search
  const urlParams = new URLSearchParams(origin);

  

  const token = urlParams.get('token');

  const onClear = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPayCurrency("");
    setConvertedAmount("");
  };

  function addTwoDecimalPlaces(number) {
    return parseFloat(number).toFixed(2);
}


  async function fetchData() {
    setLoading(true);
    const merchantToken = window.location.search.split("=")[1] || "";
    let errorStatus;
    try {
      const getPaymentByToken = await fetch(
        `${BACKEND_URL}/merchant/paylink/token/${merchantToken}`,
        {
            headers: {
                    'Content-Type': 'application/json',
                    'x-fe-token': DASHBOARD_ACCESS_TOKEN
                }
        }
      );

      if (!getPaymentByToken.ok) {
        errorStatus = getPaymentByToken.status;
        throw new Error(errorStatus);
      }
      const paymentData = await getPaymentByToken.json();

      const {
        currency,
        amount: payAmount,
        payLinkType,
        interval,
        merchantKey,
        business,
        name,
      } = paymentData;

      
      setCurrencyPreconfigured(currency !== null && currency !== undefined);
      setAmountPreconfigured(payAmount !== null && payAmount !== undefined);
      const isBaeLinkType = name.includes("BaePayApp") || name === "BaePay";
      setIsBaePayLink(isBaeLinkType);
      setCurrency(currency);
      setAmount(payAmount);
      
      if (payLinkType === "RECURRING") {
        setExtraPayload({ rememberMe: true, limit: limit });
    }
    
    setPayLinkType(payLinkType);
    
    setInterval(interval);
    
    setBusinessObj(business ?? {});
    setMerchantKey(merchantKey);
    let transactionId = uuidv4();
    if (isBaeLinkType) {
        if (name.includes("BaePayApp")) {
            transactionId = "bae-" + transactionId + "-app";
        } else {
            transactionId = "bae-" + transactionId;
        }
        setBaeEmail(paymentData.email);
    } else {
        transactionId = "paylink-" + transactionId;
    }
    setTaxRefId(transactionId);
    setPaylinkId(merchantToken);
    
    const africaPlusFlagAndCurrencies = getAfricanPlusFlagAndCurrencies(name);
    setThePayingCurrencies(africaPlusFlagAndCurrencies);
} catch (e) {
    setHasError(true);
    if ([400, 404].includes(errorStatus)) {
        setErrorType("INVALID");
        setErrorMessage("Paylink does not exist");
    } else {
        setErrorType("INTERNAL");
        setErrorMessage("Something went wrong");
    }
}

setLoading(false);
}

  //The exchange rate baseUrl is the prod baseUrl
// This is to avoid any glitch on our rates
  const fetchExcRate = async ({ sourceCurrency, destinationCurrency }) => {
    setAppLoading(true);
    let payload = JSON.stringify({
      amount: amount,
      sourceCurrency: destinationCurrency,
      destinationCurrency: sourceCurrency,
      source: "checkout",
      provider: "butter",
      businessId: businessObj?.id,
      productType: "PAYMENT_LINK"

    });

    try {
      await fetch(`${PROD_RATE_BASE_URL}/nucleus/general/exchange`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-fe-token': DASHBOARD_ACCESS_TOKEN
        },
        body: payload,
      })
          .then((res) => res.json())
          .then((resData) => {
            const convertedRate = resData.data.amount;
            setConvertedAmount(convertedRate);
            
          })
    } catch (e) {}
    setAppLoading(false);
  };


  const sortByCurrency = (currencyFlayObjects) => {
    const [...currObjCopy] = currencyFlayObjects;
    return currObjCopy.sort(function (a, b) {
      let nameA = a.currency.toUpperCase(); // ignore upper and lowercase
      let nameB = b.currency.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  };

  const getAllCurrenciesAndFlag = () => {
    // All countries + EU
    let countriesCurrency = [];
    for (const [key, value] of Object.entries(countries)) {
      if (key === "AQ") continue;
      const currencies = value.currency.split(",");

      // Take only first currency
      countriesCurrency.push({ flag: value.emoji, currency: currencies[0] });
    }

    const excludedAfricanCurrencies = [
      "AOA",
      "AFN",
      "BIF",
      "CDF",
      "CVE",
      "DJF",
      "DZD",
      "EGP",
      "ERN",
      "LYD",
      "LSL",
      "LRD",
      "KMF",
      "MRU",
      "MGA",
      "NAD",
      "MZN",
      "TND",
      "SZL",
      "STN",
      "SSP",
      "GNF",
      "SOS",
      "SHP",
      "SDG",
      "SCR",
      "GBP"
    ];
    const filteredResult = countriesCurrency.filter(
      (afCount) => !excludedAfricanCurrencies.includes(afCount.currency)
    );
    return sortByCurrency(filteredResult);
  };


  const getAfricanPlusFlagAndCurrencies = (linkName) => {
    const payingCurrencies = [
      { currency: "NGN", countries: ["NG"] },
      { currency: "KES", countries: ["KE"] },
      { currency: "UGX", countries: ["UG"] },
      { currency: "TZS", countries: ["TZ"] },
      { currency: "ZMW", countries: ["ZM"] },
      { currency: "ZAR", countries: ["ZA"] },
      { currency: "CDF", countries: ["DRC"] },
      { currency: "XOF", countries: ["SN", "CI", "BJ"] },
      { currency: "XAF", countries: ["CM"] },
      { currency: "RWF", countries: ["RW"] },
      { currency: "MWK", countries: ["MW"] },
      { currency: "SLL", countries: ["SL"] }
    ]
    if (!linkName.includes("BaePayApp")) {
      payingCurrencies.push({ country: "US", currency: "USD" });
    }
    const payingCurrenciesOptions = payingCurrencies.map((payCur) => {
      return {
        flag: countries[payCur.country]?.emoji,
        currency: payCur.currency,
      };
    });

    return sortByCurrency(payingCurrenciesOptions);
  };

  const removePaymentGatewayFromDOM = () => {
    const gatewayPopup = document.getElementById("klashaPayment");
    gatewayPopup.remove();
  };

  useEffect(() => {
    if(businessObj.publicReferrer === "paystack"){
      setReferred(true)
    }
}, [businessObj])

  useEffect(() => {
    // listen to stitch payment response
    window.addEventListener("message", (ev) => {
      if (whitelistedOrigins.includes(ev.origin)) {
        if (typeof ev.data !== "object") return;
        if (!ev.data.type) return;
        if (ev.data.type !== "klasha:stitch") return;
        if (!ev.data.status) return;
        callWhenDone(ev.data);
        removePaymentGatewayFromDOM();
      }
    });
  }, []);

  useEffect(() => {
    const allCurrencies = getAllCurrenciesAndFlag();
    setCurrencyList(allCurrencies);

    fetchData();
  }, []);

  useEffect(() => {
    if (payCurrency && payCurrency.length) {
      const payCurrencyWithoutI = payCurrency.split("-")[0];
      setPayCurrencyWithoutIndex(payCurrencyWithoutI);
    }
  }, [payCurrency]);

  useEffect(() => {
    if (payCurrency && payCurrency.length && currency && currency.length) {
      const payCurrencyWithoutI = payCurrency.split("-")[0];
      fetchExcRate({
        sourceCurrency: currency,
        destinationCurrency: payCurrencyWithoutI,
      });
    }
  }, [currency, payCurrency]);


  const callWhenDone = (data) => {
    if (data["status"] === "successful") {
      setSuccessMessage("Payment successful");
      setPaymentSucceeded(true);
    } else if (data["status"] === "pending") {
      setSuccessMessage(
        "Your payment is now being processed. You will be notified via email when payment is successful."
      );
      setPaymentSucceeded(true);
    } else {
      setPaymentSucceeded(false);
    }
  };


  const initializePayment = useKlashaPayment({
    isTestMode: process.env.REACT_APP_ENV === "dev",
    email: email,
    phoneNumber: phoneNumber,
    merchantKey: merchantKey,
    businessId: businessObj.id,
    amount: Number(amount).toFixed(2),
    destinationCurrency: payCurrencyWithoutIndex,
    sourceCurrency: currency,
    tx_ref: taxRefId,
    fullname: `${firstName} ${lastName}`,
    kit: {
      currency: currency,
      tx_ref: taxRefId,
      paymentType: payLinkType,
      fullname: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email: email,
      phone_number: phoneNumber,
      hasBae: !isBaePayLink,
      extra: isBaePayLink ? "baepay" : "",
      baeEmail: isBaePayLink ? baeEmail : undefined,
      paystack: referred,
      category: businessObj.riskCategory,
      productType: "PAYMENT_LINK",
      callBack: callWhenDone,
    },
    paymentDescription: "",
    channel: "paylink",
    extra: paylinkId, // paylink id,
    ...extraPayload,
  });

  const onSubmit = async () => {
    if (!email.match(mailformat) && email) {
      setEmailError("Please enter a valid email address");
    } else {
      initializePayment();
    }
  };

  useEffect(() => {
    if (appLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [appLoading]);


    useEffect(() => {
        const disableRightClick = (e) => e.preventDefault();
        const disableInspect = (e) => {
          if (e.ctrlKey && e.shiftKey && e.key === 'I') e.preventDefault();
        };
    
        if (flagEnabled) {

          document.addEventListener('contextmenu', disableRightClick);
          document.addEventListener('keydown', disableInspect);
        }
    
        return () => {
          document.removeEventListener('contextmenu', disableRightClick);
          document.removeEventListener('keydown', disableInspect);
        };
      }, [flagEnabled]);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.header}>
          <LogoIcon />
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <div className={styles.spin}>
              <div className={styles.spinner_logo}>
                <LogoPink />
              </div>
              <div className={styles.spinner}> </div>
            </div>
          </div>
        )}
        {appLoading && (
          <div className={styles.apploaderContainer}>
            <div className={styles.spin}>
              <div className={styles.spinner_logo}>
                <LogoPink />
              </div>
              <div className={styles.spinner}> </div>
            </div>
          </div>
        )}

        {hasError && (
          <InvalidPaymentToken message={errorMessage} errorType={errorType} />
        )}

        {paymentSucceeded && !hasError && !loading && (
          <PaymentSuccessful message={successMessage} />
        )}
        
        {!paymentSucceeded && !hasError && !loading && (
          <Form
            amount={amount}
            email={email}
            phoneNumber={phoneNumber}
            firstName={firstName}
            lastName={lastName}
            amountPreconfigured={amountPreconfigured}
            currencyPreconfigured={currencyPreconfigured}
            currency={currency}
            currencyList={currencyList}
            interval={interval}
            limit={limit}
            setInterval={setInterval}
            payCurrency={payCurrency}
            payLinkType={payLinkType}
            setAmount={setAmount}
            setCurrency={setCurrency}
            setEmail={setEmail}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPayCurrency={setPayCurrency}
            setPhoneNumber={setPhoneNumber}
            setLimit={setLimit}
            onSubmit={onSubmit}
            thePayingCurrencies={thePayingCurrencies}
            convertedAmount={addTwoDecimalPlaces(convertedAmount)}
            onClear={onClear}
            errorText={emailError}
            onFocus={() => setEmailError("")}
          />
        )}

        <div className={styles.footer}>
          <p>
            Email: <a href="mailto:support@klasha.com">support@klasha.com</a>
          </p>
          <p>
            WhatsApp: <a href="https://wa.me/+12138630549">+1 (213) 863-0549</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
