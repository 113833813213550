/**
 * This file exports environmental variables depending on the server environment
 */

const  {
    REACT_APP_API_URL,
    REACT_APP_DASHBOARD_ACCESS_TOKEN,
} = process.env

export const BACKEND_URL = REACT_APP_API_URL
// REACT_APP_API_URL
//  REACT_APP_API_URL
export const PROD_RATE_BASE_URL = REACT_APP_API_URL
export const REACT_APP_POSTHOG_KEY = ""


export const DASHBOARD_ACCESS_TOKEN =  REACT_APP_DASHBOARD_ACCESS_TOKEN
