/* eslint-disable react-hooks/exhaustive-deps */
import { useForm, Controller } from "react-hook-form";
import styles from '../../styles/Form.module.css';
import { ReactComponent as LogoWhiteIcon } from "../../assets/icons/logo-light.svg";
import FormInput from "../FormInput";
import { useEffect, useState } from "react";
import CurrencySelect from "../CurrencySelect";
import PayingCurrencySelect from "../CurrencySelect/PayingCurrency";
import FormPhone from "../FormPhone";

function Form(
	{
		currencyPreconfigured, amountPreconfigured, setFirstName, setLastName, setEmail, setPhoneNumber, currency,
		setCurrency, payCurrency, setPayCurrency, currencyList, amount, setAmount, payLinkType, interval, setInterval,
		setLimit, onSubmit, thePayingCurrencies, convertedAmount, onClear, email, phoneNumber, firstName, lastName,
		limit, errorText, onFocus

	}) {
	const { control, handleSubmit, formState: { errors }, reset } = useForm();
	const [showRecurringInterval, setShowRecurringInterval] = useState(null)

	useEffect(() => {
		setShowRecurringInterval(!interval && payLinkType === 'RECURRING')
	},
		[]);

	const onCancelClick = () => {
		reset({
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
		})
		onClear()
	}

	return (
		<div className={styles.form}>
			{
				payLinkType === 'RECURRING' && (
					<div className={styles.formSubHead}>
						<p>You will be
							charged {amount ? <span>{currency} {amount} </span> : null}every <span>{interval ?? 30}</span> days</p>
					</div>
				)
			}
			<form className={styles.formBody} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.formDiv}>
					<Controller
						name="firstName"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => (
							<FormInput value={firstName} dataContent='First name' setValue={setFirstName} field={field} errorMessage={errors.firstName?.type === "required" && "First name is required"} />
						)}
					/>
					<Controller
						name="lastName"
						control={control}
						rules={{ required: true }}
						render={({ field }) => (
							<FormInput value={lastName} dataContent='Last name' setValue={setLastName} field={field} errorMessage={errors.lastName?.type === "required" && "Last name is required"} />
						)}
					/>
				</div>
				<div>
					<Controller
						name="email"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => (
							<FormInput
								value={email}
								dataContent='Email address'
								inputType={'text'}
								setValue={setEmail}
								field={field}
								errorMessage={errors.email?.type === "required" ? "Email is required" : errorText}
								onFocus={onFocus}
							/>
						)}
					/>
				</div>
				<Controller
					name="phone"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<FormPhone value={phoneNumber} dataContent='Phone number'
							field={field} inputType={'tel'} setValue={setPhoneNumber}
							errorMessage={errors.phone?.type === "required" && "Phone number is required"}
						/>
					)}
				/>
				<div className={[styles.formDiv, styles.formDivDisabled].join(" ")}>
					{
						currencyPreconfigured ?
							<FormInput dataContent='Source currency' readOnly={amountPreconfigured} value={currency} inputType="text" />
							:
							<CurrencySelect dataContent='Source currency' disabled={currencyPreconfigured} value={currency}
								setValue={setCurrency} options={currencyList} />
					}
					<FormInput dataContent='Amount' readOnly={amountPreconfigured} value={amountPreconfigured ? parseFloat(amount)?.toFixed(2) : amount}
							   setValue={setAmount} inputType={'number'} />
				</div>
				<div className={styles.formDiv}>
					<PayingCurrencySelect dataContent='Paying currency' options={thePayingCurrencies} value={payCurrency}
						setValue={setPayCurrency} />
					<FormInput dataContent='Amount' readOnly value={convertedAmount}
						setValue={() => { }} inputType={'number'} />
				</div>
				<div className={styles.formDiv}>
					<div className={styles.recurringInterval}>
						{
							showRecurringInterval && (
								<div className={styles.recurringIntervalInputs}>
									<FormInput value={interval} label={'Interval (days)'} inputType={'number'} setValue={setInterval} />
									<FormInput value={limit} label={'Limit'} inputType={'number'} setValue={setLimit} />
								</div>
							)
						}
					</div>
				</div>
				<button type='submit' className={styles.payBtn} disabled={!firstName.length || !lastName.length || !phoneNumber.length || !payCurrency.length || !email.length || !currency.length}>
					<span>Pay with </span>
					<LogoWhiteIcon className={styles.payBtnLogo} />
				</button>
				<button type='button' className={styles.cancelBtn} onClick={onCancelClick}>
					Cancel
				</button>
			</form>
		</div>
	);
}

export default Form;
