import styles from './InvalidToken.module.css';
import { ReactComponent as FailedPaymentIcon } from "../../assets/icons/Feeback-Message-fail.svg"

const InvalidPaymentToken = ({ errorType, message }) => {
	return (
		<div className={styles.invalidPaymentToken}>
			<FailedPaymentIcon />
			<div className={styles.invalidPaymentTexts}>
				<p className={styles.invalidPaymentText}>{message}</p>
				<p className={styles.invalidPaymentSubText}>
					{
						errorType === 'INVALID'
							? 'Please reach out to the merchant'
							: 'Please try again later'
					}
				</p>
			</div>
		</div>
	);
}

export default InvalidPaymentToken;
