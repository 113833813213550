import styles from '../../styles/Form.module.css';
import { ReactComponent as Caret } from "../../assets/icons/dropdown.svg";

const CurrencySelect = ({ label, options = [], disabled, value, setValue }) => {
	return (
		<div className={styles.formSelectInput}>
			<p className={styles.formInputLabel}>{label}</p>
			<div className={styles.selectDivDiv}>
				<div className={styles.selectDiv}>
					<Caret className={styles.dropdownBtn} />
					<select
						required={true}
						className={styles.selectInputField}
						disabled={disabled} value={value}
						onChange={(e) => setValue(e.target.value)}
					>
						<option value="">Select</option>
						{
							options.map((opt, index) => {
								return (
									<option key={index} value={opt.currency}>
										{`${opt.flag} ${opt.currency}`}
									</option>
								)
							})
						}
					</select>
				</div>
			</div>
		</div>
	)
}

export default CurrencySelect;
