import styles from '../../styles/Form.module.css';

const FormInput = ({ label, readOnly, value, setValue, inputType = 'text', pattern, field, dataContent, errorMessage, onFocus }) => {
	const onChangeValue = (e) => {
		field && field.onChange(e)
		setValue(e.target.value)

	}

	return (
		<div className={styles.formInputContainer}>
			<div className={styles.formInput} data-content={dataContent}
				onFocus={onFocus}
			>
				{
					label && <p className={styles.formInputLabel}>{label}</p>
				}
				<input
					readOnly={readOnly} value={value}
					type={inputType}
					pattern={pattern}
					{...field}
					onChange={onChangeValue}

				/>
			</div>
			{
				errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>
			}
		</div>

	)
}

export default FormInput;
