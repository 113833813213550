import React from "react";
import ReactDOM from "react-dom";
import { PostHogProvider} from 'posthog-js/react';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const options = {
    api_host: "https://app.posthog.com",
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
  }
  

ReactDOM.render(
  <React.StrictMode>
  <PostHogProvider options={options}
  apiKey={process.env.REACT_APP_POSTHOG_KEY}>
  <App />
  </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
