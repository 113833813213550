import styles from '../../styles/PaymentSuccessful.module.css';
import { ReactComponent as SuccessPaymentIcon } from "../../assets/icons/Feeback-Message-success.svg"

function PaymentSuccessful({ message = 'Payment successful' }) {
	return (
		<div className={styles.paymentSuccessfulContainer}>
			<SuccessPaymentIcon />
			<div className={styles.message}>{message}</div>
		</div>
	);
}

export default PaymentSuccessful;
